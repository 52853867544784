<template>
  <PullRefresh
    :loading="loading"
    :refreshing="refreshing"
    :finished="finished"
    @onLoad="onLoad"
    @onRefresh="onRefresh"
    :isHigehtMax="true"
    :isNoData="isNoData"
    :error="error"
  >
    <MovieList :dataList="list" />
  </PullRefresh>
</template>
<script>
import MovieList from "@/components/MovieList";
import PullRefresh from "@/components/PullRefresh";
import { getSection } from "@/api/movies";

export default {
  name: "moviesMore",
  components: {
    MovieList,
    PullRefresh,
  },
  data() {
    return {
      sortType: "hot",
      params: {
        pageNumber: 1,
        pageSize: 20,
      },
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      AdvNum: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      let req = {
        sectionID: this.$route.query.id,
        pageNumber: String(this.params.pageNumber),
        pageSize: String(this.params.pageSize),
        sortType: this.sortType,
        playTimeType: "1",
      };
      try {
        let res = await this.$Api(getSection, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.videos || [];
          if (type === "refresh") {
            this.list = list;
            this.AdvNum = 0;
          } else {
            this.list = this.list.concat(list);
          }
          let leng = this.list.length;
          let AdvNum = this.AdvNum;
          for (let i = 0; i < leng; i++) {
            if ((AdvNum + 1) * 4 + AdvNum < this.list.length) {
              if (this.list[(AdvNum + 1) * 4 + AdvNum]["type"] == 0) {
                continue;
              }
              if (this.$store.getters.adsBannerList.list.length > 0) {
                let itemAd =
                  this.$store.getters.adsBannerList.list[
                    this.$store.getters.adsBannerList.index
                  ];
                itemAd.type = 0;
                this.list.splice((AdvNum + 1) * 4 + AdvNum, 0, itemAd);
                this.$store.commit("video/UPDATE_ADS_BANNER_LIST_INDEX");
                AdvNum += 1;
              } else {
                break;
              }
            }
          }
          this.AdvNum = AdvNum;
          if (this.params.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.error = true;
        }
      } catch (e) {
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.params.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.params.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getList("refresh");
    },
  },
};
</script>
